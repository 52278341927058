import React, { useState, useRef,useLayoutEffect,useEffect } from 'react';
//import {useSelector} from "react-redux";
import { Link } from 'react-router-dom';
import { project } from '../../../utils/project';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { HashLink } from 'react-router-hash-link';
import { language } from '../../../utils/lang.js';
import { customShowLoadingLayer, customHideLoadingLayer, showWarningLayer, hideWarningLayer } from '../../../utils/utils';
import * as actionCreators from '../../../redux/login/login.actions';
import { ProjectActionTypes } from '../../../redux/project/project.types';
import Menu from '../menu/menu.component';

import './navbar.styles.scss';


const NavBar = ({ menutype }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const stickyHeader = useRef();
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleOpenLogin = () => dispatch(actionCreators.setFormLoginStatus(true));


  const logOut = () => dispatch(actionCreators.userLogout());
  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload });
  }

  const setLanguage = () => {
    const defaultLang = xprops.project.language.short === "TR" ? "EN" : "TR";
    const tmpLang = language[defaultLang];
    dispatch({ type: ProjectActionTypes.SET_LANGUAGE, language: tmpLang });
  }

  const handleLogout = (event) => {
    customShowLoadingLayer();
    onWarning({ warningMessageType: 'success', warningMessageText: 'Çıkış yapıldı.' });
    showWarningLayer();
    setTimeout(() => {
      logOut();
      customHideLoadingLayer();
      hideWarningLayer();
    }, 3000);
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;


  useLayoutEffect(() => {
    const mainHeader = document.getElementById('navID');
    let fixedTop = mainHeader.offsetTop;
    
    const mainId = document.getElementById('mainID');
    const fixedHeader = () => {
      //console.log(window.pageYOffset);
      if (!isMobile){
        if (window.pageYOffset >= 50) {
          if (menutype !== "nosticky"){
            mainId.style.paddingTop = "100px";
            mainHeader.classList.add('navbarsticky');
          }
  
        } else {
          mainHeader.classList.remove('navbarsticky');
          mainId.style.paddingTop = "0px";
        }
  
      }
    }
    window.addEventListener('scroll', fixedHeader);    
  }, [])  


  return (
    <React.Fragment>
      <nav id="navID" className="navbarhome" ref={stickyHeader}>
        <div className="navbarhome-container">
          <Link to="/" className="logoContainer">
            <img className="navbarhome-logo" src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt={project.projectName} />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>

            {
              click &&
              <button className="navmenuCloseBtn" onClick={closeMobileMenu}></button>
            }

            <li className="nav-item-main">
              <Link
                to='/uzman-bul'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                {xprops.project.language.keywords.findExpert}
              </Link>
            </li>
            <li className="nav-item-main">
              <Link
                to='/hakkimizda'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                {xprops.project.language.keywords.aboutUs}
              </Link>
            </li>
            <li className="nav-item-main">
              <Link
                to='/blog'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                {xprops.project.language.keywords.articles}
              </Link>
            </li>
            <li className="nav-item-main">
              <Link
                to='/egitimler'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                {xprops.project.language.keywords.egitimler}
              </Link>
            </li>
            <li className="nav-item-main">
              <Link
                to='/toplantilar'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                {xprops.project.language.keywords.toplantilar}
              </Link>
            </li>
            <li className="nav-item-main">
              <Link
                to='/farkindalik-calismalari'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                {xprops.project.language.keywords.farkindalikCalismalari}
              </Link>
            </li>
            <li className="nav-item-main">
              <Link
                to='/mentorluk'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                {xprops.project.language.keywords.mentorluk}
              </Link>
            </li>
            <li className="nav-item-main">
              <Link
                to='/iletisim'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                {xprops.project.language.keywords.contactUs}
              </Link>
            </li>
            {
              xprops.login.isLoggedIn === true ?
                <React.Fragment>
                  <li className="nav-item-main">
                    <Link
                      to='/profil'
                      className='nav-links-main'
                      onClick={closeMobileMenu}
                    >
                      {xprops.project.language.keywords.profile}
                    </Link>
                  </li>
                </React.Fragment>
                :
                <React.Fragment>
                  <li className="nav-item-main">
                    <Link
                      to='/signup'
                      className='nav-links-main'
                      onClick={closeMobileMenu}
                    >
                      {xprops.project.language.keywords.signUp}
                    </Link>
                  </li>
                  <React.Fragment>
                    <Link
                      to='/giris'
                      className='nav-links-main'
                      onClick={closeMobileMenu}
                    >
                      {xprops.project.language.keywords.signIn}
                    </Link>
                  </React.Fragment>
                </React.Fragment>
            }
              <React.Fragment>
                <li className="nav-item-main" onClick={setLanguage}>
                  <span className="nav-links-main">{xprops.project.language.short === "TR" ? "ENGLISH" : "TÜRKÇE"}</span>
                </li>
              </React.Fragment>
            {
              xprops.login.isLoggedIn === true &&
              <React.Fragment>
                <li className="nav-item-main" onClick={handleLogout}>
                  <span className="nav-links-main">{xprops.project.language.keywords.signOut}</span>
                </li>
              </React.Fragment>
            }


          </ul>
          <div className="mainMenuWrapper">
            <Menu /> 
            {
              menutype !== "nosticky" &&
                <ul className={click ? 'nav-menu-sub active' : 'nav-menu-sub'}>
                    <React.Fragment>
                      <li className="nav-item-sub"><span className="linka" onClick={setLanguage}>{xprops.project.language.short === "TR" ? "EN" : "TR"}</span></li>
                    </React.Fragment>                  
                {
                  xprops.login.isLoggedIn === true ?
                    <React.Fragment>
                      <li className="nav-item-sub"><Link to='/profil' className="linka">{xprops.project.language.keywords.profile}</Link></li>
                      <li className="nav-item-sub-right"><button className="btnSignout" onClick={handleLogout}>{xprops.project.language.keywords.signOut}</button></li>           
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <li className="nav-item-sub"><Link to='/giris' className="linka">{xprops.project.language.keywords.signIn}</Link></li>
                    </React.Fragment>
                }
              </ul>      
            }
            {
              menutype === "show" &&
                <ul className={click ? 'nav-menu-sub active' : 'nav-menu-sub'}>
                {
                  xprops.login.isLoggedIn === true ?
                    <React.Fragment>
                      <li className="nav-item-sub"><Link to='/profil' className="linka">{xprops.project.language.keywords.profile}</Link></li>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {/* <li className="nav-item-sub"><Link to='/giris' className="linka">{xprops.project.language.keywords.signIn}</Link></li> */}
                      {
                        xprops.login.isLoggedIn === true ?
                        <React.Fragment>
                          <li className="nav-item-sub-right">{xprops.login.username} {xprops.project.language.keywords.welcome}! | <button className="btnSignout" onClick={handleLogout}>{xprops.project.language.keywords.signOut}</button></li>           
                        </React.Fragment>
                        :
                        <React.Fragment>
                          {/* <li className="nav-item-sub-right"><Link to='/signup' className="linka" >{xprops.project.language.keywords.signUp}</Link></li> */}
                        </React.Fragment>
                      }                
                    </React.Fragment>
                }
                
              </ul>      
            }            
                   

          </div>
       
        </div>

              
      </nav>
      {/* <div className="menu-icon" onClick={handleClick}>
        <i className={click ? 'fas' : 'fas fa-bars'} />
      </div> */}

    </React.Fragment>
  )
};


export default NavBar;

